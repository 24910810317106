import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Whakamatakitaki from "../components/whakamatakitaki";
import KiwahaTwo from "../components/kiwahaTwo";

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Kiwaha() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKiwaha {
        edges {
          node {
            termEnglish
            termMaori
          }
        }
      }
    }
  `);
  const info = shuffle(data.allContentfulKiwaha.edges);
  return (
      <Layout>
        <SEO title="Kīwaha" />
        <Whakamatakitaki collectionName="Kīwaha" subtitle="Idioms">
          {info.map(x => (
            <KiwahaTwo node={x.node} />
          ))}
        </Whakamatakitaki>
      </Layout>
  );
}


export default Kiwaha