import React from "react";

import "./kiwahaTwo.css";

const setFontSize = (str, sz1, sz2, sz3) => {
  const length = str.length;

  if (length < 7) {
    return { fontSize: sz1 };
  } else if (length >= 7 && length < 15) {
    return { fontSize: sz2 };
  } else if (length >= 15) {
    return { fontSize: sz3 };
  }
};

const KiwahaTwo = props => {
  return (
    <div className="kupu-wrapper-two">
      <div className="kupu-container-two">
        <div className="item-term-maori-two">
          <p
            style={setFontSize(props.node.termMaori, "60px", "50px", "50px")}
            className="term-maori-two"
          >
            {props.node.termMaori}
          </p>
        </div>
        <div className="item-divider-two">
          <p className="divider"></p>
        </div>
        <div className="item-term-english-two">
          <p
            style={setFontSize(props.node.termEnglish, "60px", "50px", "50px")}
            className="term-english-two"
          >
            {props.node.termEnglish}
          </p>
        </div>
      </div>

      <div className="kupu-container-two-mobile">
        <div className="item-mobile">
          <p className="kupu-maori-mobile">{props.node.termMaori}</p>
        </div>
        <div className="item-divider-mobile">
          <div className="divider-mobile"></div>
        </div>
        <div className="item-mobile">
          <p className="term-maori-mobile">{props.node.termEnglish}</p>
        </div>
      </div>
    </div>
  );
};

export default KiwahaTwo;
